import * as ImageConstants from "../../../constants/images"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import PaginationFrontend from "@components/UI/PaginationFrontend"
import { ColProps } from "react-bootstrap/Col"
import { Spin } from "antd"
import { LocalizedCompetitionSeasonTeamStatsWithParameterValue } from "../../../types/competitionSeasonTeamStats"
import { getTeamPageUrl } from "../../../utils/urls"
import Link from "next/link"
import Image from "next/image"

export function CompetitionSeasonTeamParameterStandings({
  teamParameterStandings,
  unitMeasurement,
  col,
  loading,
}: {
  teamParameterStandings: LocalizedCompetitionSeasonTeamStatsWithParameterValue[]
  unitMeasurement?: string | null
  col?: ColProps
  loading?: boolean
}) {
  const isMobile = (typeof window !== "undefined" && window?.matchMedia("(max-width: 992px)")?.matches) || false

  const [teamParameterStandingsToDisplay, setTeamParameterStandingsToDisplay] = useState<
    LocalizedCompetitionSeasonTeamStatsWithParameterValue[]
  >([])
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    if (isMobile && page) {
      setTeamParameterStandingsToDisplay(teamParameterStandings.slice(10 * (page - 1), 10 * page))
    } else {
      setTeamParameterStandingsToDisplay(teamParameterStandings)
    }
  }, [isMobile, teamParameterStandings, page])

  return (
    <Col className="competition-season-team-parameter-standings-cc" {...col}>
      <Row>
        <Col>
          <div className="container-div">
            <Spin spinning={loading}>
              <Row>
                <Col>
                  {teamParameterStandingsToDisplay.map((teamParameter, index) => {
                    const isOdd = (index + 1) % 2 === 1
                    const teamUrl = getTeamPageUrl(teamParameter.calciocomSlug!)

                    return (
                      <Row key={index} className={`${isOdd ? "odd" : "even"} team-parameter-row g-0`}>
                        <Col lg={12} xs={12} className={"team-parameter-col"}>
                          <div className={"rank"}>{(page - 1) * 10 + index + 1}</div>
                          <>
                            <Link href={teamUrl} passHref>
                              <a>
                                <div className={"team-image-div"}>
                                  <Image
                                    blurDataURL={ImageConstants.TEAM_MISSING_IMAGE_PATH}
                                    src={teamParameter.image?.path || ImageConstants.TEAM_MISSING_IMAGE_PATH}
                                    alt={teamParameter.name}
                                    className={"image"}
                                    placeholder="blur"
                                    height={32}
                                    width={32}
                                    layout={"intrinsic"}
                                    objectFit={"fill"}
                                  />
                                </div>
                              </a>
                            </Link>
                            <Link href={teamUrl} passHref>
                              <a>
                                <div className={"team-name"}>{teamParameter.name}</div>
                              </a>
                            </Link>
                          </>
                          <div className={"value"}>
                            {teamParameter.parameterValue !== undefined && teamParameter.parameterValue !== null
                              ? `${teamParameter.parameterValue.toFixed(2).replace(".00", "")}${unitMeasurement || ""}`
                              : "-"}
                          </div>
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
              </Row>
            </Spin>
          </div>
        </Col>
      </Row>
      <Row className={"pagination-row"}>
        <PaginationFrontend
          page={page}
          pageCount={Math.round(teamParameterStandings.length / 10)}
          onChange={(page) => {
            setPage(page)
          }}
        />
      </Row>
    </Col>
  )
}
